import * as React from "react";
import { api } from "../../lib/api";
import { Block } from "../../models/";
import parse from 'html-react-parser'


interface Props {

}

interface GetChannelProps {
  blocks: Block[];
  status:string;
  error:string;
  get: () => void;
}

interface GetChannelState {
  blocks: Block[];
  status:string;
  error:string;
}


const useGetChannel = (): GetChannelProps => {
  const [channelData, setChannelData] = React.useState<GetChannelState>({
    blocks:[],
    status:"idle",
    error:""
  });

  const url:string = 'question-mark-spgpec44rso'; 

  const get = React.useCallback(async () => {
    api.get(`https://api.are.na/v2/channels/`+url+`/contents?page=1&per=100`,
    json => {
      setChannelData({blocks:json.contents, status:"success", error:""})
    }, 
    (error, message) => {
      setChannelData({blocks:[], status:"error", error:message})
    }); 
  },[]);

  return {
    blocks: channelData.blocks,
    status: channelData.status,
    error: channelData.error,
    get
  };  

}




export const ArenaPage:React.FC<Props> = (props:Props) => {


  const loader = useGetChannel();
  const { blocks, get } = loader;



  React.useEffect(()=>{ get(); }, []);

  const byChannel = blocks.slice(0).reverse().map((r) => {
    if (r.class === "Text") 
      return <div className="PlazaBox PlazaText" key={r.id}>
        <div>{r.content}</div>
        <div className="PlazaMetaText">{r.description}  {r.source != null ? <a href={r.source.url}>{r.source.title}</a>:<div></div>}</div>

      </div>
    if (r.class === "Link")
      return <div className="PlazaBox" key={r.id}>
        <div className="ContentBox"><a href={r.source.url} target="_blank"><img src={r.image.display.url} /></a></div>
        <div className="PlazaMeta"><a href={r.source.url}>{r.title}</a> — {r.description}</div>
      </div>
    if (r.class === "Image")
      return <div className="PlazaBox" key={r.id}>
        <div className="ContentBox"><img src={r.image.display.url} /></div>
        {r.description != "" ? <div className="PlazaMeta">{r.description}</div>:<div></div>} 
        {r.title != "" ? <div className="PlazaMetaSource">{r.title}</div>:<div></div>} 
      </div>
    if (r.class === "Attachment")
      return <div className="PlazaBox" key={r.id}>
        <div className="ContentBox">
          {r.attachment.content_type === "video/mp4" ? <div className="ContentBox"><video controls autoPlay loop muted width="500"><source src={r.attachment.url} type={r.attachment.content_type} /></video></div>:<div></div>}
          {r.attachment.content_type === "application/pdf" ? <div className="ContentBox"><img src={r.image.display.url} /></div>:<div></div>}
        </div>
        {r.description != "" ? <div className="PlazaMeta">{r.description}</div>:<div></div>} 
     </div>
    if (r.class === "Media")
      return <div className="PlazaBox" key={r.id}>
        <div className="ContentBox">{parse(r.embed.html)}</div>
        {r.description != "" ? <div className="PlazaMeta">{r.description}</div>:<div></div>} 
        {r.title != "" ? <div className="PlazaMetaSource">{r.title}</div>:<div></div>} 
      </div>     
  });  


  return (
    <div className="InfoBox">
      Imagining new spaces for creators and the future of visual research, creation, and exchange within a collective context. A VSCO Thing. 
      <div className="PlazaVideoBox">
      <iframe 
  className="PlazaBgVideo"
  src="https://www.youtube.com/embed/P5hr_lgLaFM?playlist=P5hr_lgLaFM&autoplay=1&mute=1&controls=0&loop=1&showinfo=0&rel=0"
  frameBorder="0"
  allowFullScreen
      />
      </div>
    </div>
  );
};