export type ApiResult = Record<string, any>;
export type Body = Record<string, any>;
type OnSuccess = (json: ApiResult) => void;
type OnError = (error: string, message: string) => void;

const get = (path: string, onSuccess: OnSuccess, onError: OnError) => {
  fetch(path, { method: 'GET' })
    .then(resp => {
      if (resp.ok) {
        return resp.json()
          .then(json => onSuccess(json))
          .catch(e => onError("error", "The response was not formatted correctly"));
      } else if (resp.status === 401) {
        onError("unauthorized", "Unable to authenticate user");
        return null;
      } else {
        resp.text()
          .then(text => onError("error", text))
          .catch(e => onError("error", e.toString()));
      }
    })
    .catch(e => onError("error", e.toString()));
};

const del = (path: string, onSuccess: OnSuccess, onError: OnError) => {
  fetch(path, { method: 'DELETE' })
    .then(resp => {
      if (resp.ok) {
        return resp.json()
          .then(json => onSuccess(json))
          .catch(e => onError("error", "The response was not formatted correctly"));
      } else if (resp.status === 401) {
        onError("unauthorized", "Unable to authenticate user");
        return null;
      } else {
        resp.text()
          .then(text => onError("error", text))
          .catch(e => onError("error", e.toString()));
      }
    })
    .catch(e => onError("error", e.toString()));
};

const post = (path: string, body: Body, onSuccess: OnSuccess, onError: OnError) => {
  fetch(path, { method: 'POST', body: JSON.stringify(body) })
    .then(resp => {
      if (resp.ok) {
        return resp.json()
          .then(json => onSuccess(json))
          .catch(e => onError("error", "The response was not formatted correctly"));
      } else if (resp.status === 401) {
        onError("unauthorized", "Unable to authenticate user");
        return null;
      } else {
        resp.text()
          .then(text => onError("error", text))
          .catch(e => onError("error", e.toString()));
      }
    })
    .catch(e => onError("error", e.toString()));
};

export const api = {
  get: get,
  post: post,
  delete: del,
};