import React from 'react';
import './App.css';
import ArenaPage from "./pages/arena";

const App = () => {

 


  return (
    <div className="App">
      <header className="PlazaHeader">
        <div><strong><a href="/">PLAZA</a></strong></div>
        <div>Spaces for teams to research, create, and exchange.</div>

        <div className="SocialLinks">
          <div><a href="https://www.instagram.com/plaza.computer/" target="_blank">Instagram</a></div>
        </div>
      </header>
      <ArenaPage />
    </div>
  );
}

export default App;
